import { usePermission } from '../contexts/GlobalContext'

export const useCheckPermission = () => {
    const permissions = usePermission()

    const checkPermission = permissionKey => {
        let isPermissionGranted = false
      
        if(permissions.length > 0) isPermissionGranted = permissions.find(item => item.key === permissionKey).value
        
        return isPermissionGranted
    }

    return {
        checkPermission: checkPermission
    }
}