import { staticPageTabs } from '../constants'
import {
  Grid,
  Typography,
  styled,
  Box,
  Tab,
  Tabs
} from '@mui/material';

const PageHeader = ({ pageTitle, pageSubtitle, handleTabsChange, activeTab }) => {
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {pageTitle}
          </Typography>
          <Typography variant="subtitle2">
            {pageSubtitle}
          </Typography>
        </Grid>
        <StyledTabsWrapper>
          <TabsWrapper
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={activeTab.get('tab') || 'Details'}
            variant="scrollable"
          >
            {staticPageTabs.map((tab) => (
              <Tab key={tab} value={tab} label={tab} />
            ))}
          </TabsWrapper>
        </StyledTabsWrapper>
      </Grid>
    </>
  );
}

export default PageHeader;

const StyledTabsWrapper = styled(Box)(
  () => `
      display: flex;
      justify-content: flex-end;
  `
);

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);