import React from 'react';

import {
    Box,
    Button,
    Typography,
    styled,
    Card
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const SuccessCard = ({ message, onClickButton }) => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Box
                style={{
                    maxWidth: '100%',
                    width: '400px',
                }}
            >
                <StyledNotificationBox>
                    <CheckCircleOutlineIcon style={{ width: '70px', height: '70px', color: '#82d271' }} />
                    <Typography mt={2} variant="h2" style={{ marginBottom: '20px', textAlign: 'center', color: '#82d271' }}>
                        Success!
                    </Typography>
                    <Typography variant="h4" style={{ marginBottom: '20px', textAlign: 'center' }}>
                        {message}
                    </Typography>
                    {onClickButton && 
                        <Button
                            sx={{
                                mt: 3
                            }}
                            onClick={onClickButton}
                            size="medium"
                            variant="contained"
                            style={{backgroundColor: 'rgb(113 192 210)'}}
                        >
                            {'Redirect to app'}
                        </Button>
                    }
                </StyledNotificationBox>

            </Box>
        </Box>

    )
}

export default SuccessCard

const StyledNotificationBox = styled(Card)(
    () => `
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
  `
);
