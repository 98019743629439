import React, { useState, useEffect } from 'react'
import {
    Box,
    Grid,
    Typography,
    styled,
    Card,
    TextField,
    CircularProgress
} from '@mui/material';
import CustomButton from '../../components/CustomButton'
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { adminService } from '../../api/services/admin';
import { useNavigate, useSearchParams } from "react-router-dom";
import { staticPagesId, staticPageIdDict, languages, staticPageTabs } from './constants'
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import { useCheckPermission } from '../../hooks/useCheckPermission'
import { ROUTE_CONSTANTS } from '../../constants/AppRoutes'
import PageTitleWrapper from '../../components/PageTitleWrapper';
import PageHeader from './components/PageHeader';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"


const EditStatics = () => {
    const [activeTab, setActiveTab] = useSearchParams({ tab: 'Terms' })
    const [termsTitleEditMode, setTermsTitleEditMode] = useState({
        en: false,
        ar: false
    })
    const [privacyTitleEditMode, setPrivacyTitleEditMode] = useState({
        en: false,
        ar: false
    })
    const [aboutUsTitleEditMode, setAboutUsTitleEditMode] = useState({
        en: false,
        ar: false
    })
    const [termsEn, setTermsEn] = useState({ title: '', text: '' })
    const [termsAr, setTermsAr] = useState({ title: '', text: '' })

    const [privacyEn, setPrivacyEn] = useState({ title: '', text: '' })
    const [privacyAr, setPrivacyAr] = useState({ title: '', text: '' })

    const [aboutUsEn, setAboutUsEn] = useState({ title: '', text: '' })
    const [aboutUsAr, setAboutUsAr] = useState({ title: '', text: '' })


    const handleTabsChange = (_event, val) => {
        let selectedTab = staticPageTabs.find(tab => tab === val)
        setActiveTab({ tab: selectedTab })
    }

    const handleUpdate = async (id, data) => {
        try {
            await adminService.editStaticPage(id, data)
        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = (e, id, language, type) => {
        switch (staticPageIdDict[id]) {
            case 'about_us':
                if (language === 'EN') {
                    if (type === 'body') setAboutUsEn(obj => ({ ...obj, text: e }))
                    else setAboutUsEn(obj => ({ ...obj, title: e.target.value }))
                }
                else {
                    if (type === 'body') setAboutUsAr(obj => ({ ...obj, text: e }))
                    else setAboutUsAr(obj => ({ ...obj, title: e.target.value }))
                }
                break;
            case 'terms':
                if (language === 'EN') {
                    if (type === 'body') setTermsEn(obj => ({ ...obj, text: e }))
                    else setTermsEn(obj => ({ ...obj, title: e.target.value }))
                }
                else {
                    if (type === 'body') setTermsAr(obj => ({ ...obj, text: e }))
                    else setTermsAr(obj => ({ ...obj, title: e.target.value }))
                }
                break;
            case 'privacy':
                if (language === 'EN') {
                    if (type === 'body') setPrivacyEn(obj => ({ ...obj, text: e }))
                    else setPrivacyEn(obj => ({ ...obj, title: e.target.value }))
                }
                else {
                    if (type === 'body') setPrivacyAr(obj => ({ ...obj, text: e }))
                    else setPrivacyAr(obj => ({ ...obj, title: e.target.value }))
                }
                break;
            default:
                break;
        }
    }

    const getStaticTexts = async () => {
        try {
            let res = await adminService.getStaticPages()
            const items = res.data.data.items

            items.forEach(item => {
                let contentBlockEn = htmlToDraft(item.html)
                let contentBlockAr = htmlToDraft(item.htmlAr)
                let contentStateEn = ContentState.createFromBlockArray(contentBlockEn.contentBlocks)
                let contentStateAr = ContentState.createFromBlockArray(contentBlockAr.contentBlocks)
                let editorStateEn = EditorState.createWithContent(contentStateEn)
                let editorStateAr = EditorState.createWithContent(contentStateAr)

                switch (staticPageIdDict[item.id]) {
                    case 'terms':
                        setTermsEn({ title: item.title, text: editorStateEn })
                        setTermsAr({ title: item.titleAr, text: editorStateAr })
                        break;
                    case 'privacy':
                        setPrivacyEn({ title: item.title, text: editorStateEn })
                        setPrivacyAr({ title: item.titleAr, text: editorStateAr })
                        break;
                    case 'about_us':
                        setAboutUsEn({ title: item.title, text: editorStateEn })
                        setAboutUsAr({ title: item.titleAr, text: editorStateAr })
                        break;
                    default:
                        break;
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getStaticTexts()
    }, [])

    const renderEditor = () => {
        switch (activeTab.get('tab')) {
            case 'Terms':
                return (
                    <>
                        <MyEditor
                            titleObject={{
                                text: termsEn.title,
                                state: termsTitleEditMode.en,
                                setState: setTermsTitleEditMode
                            }}
                            id={staticPagesId['terms']}
                            editorValue={termsEn.text}
                            language={languages.EN}
                            onChange={handleChange}
                            handleUpdate={handleUpdate}
                        />
                        <MyEditor
                            titleObject={{
                                text: termsAr.title,
                                state: termsTitleEditMode.ar,
                                setState: setTermsTitleEditMode
                            }}
                            id={staticPagesId['terms']}
                            editorValue={termsAr.text}
                            language={languages.AR}
                            onChange={handleChange}
                            handleUpdate={handleUpdate}
                        />
                    </>
                )
            case 'Privacy':
                return (
                    <>
                        <MyEditor
                            titleObject={{
                                text: privacyEn.title,
                                state: privacyTitleEditMode.en,
                                setState: setPrivacyTitleEditMode
                            }}
                            id={staticPagesId['privacy']}
                            editorValue={privacyEn.text}
                            language={languages.EN}
                            onChange={handleChange}
                            handleUpdate={handleUpdate}
                        />
                        <MyEditor
                            titleObject={{
                                text: privacyAr.title,
                                state: privacyTitleEditMode.ar,
                                setState: setPrivacyTitleEditMode
                            }}
                            id={staticPagesId['privacy']}
                            editorValue={privacyAr.text}
                            language={languages.AR}
                            onChange={handleChange}
                            handleUpdate={handleUpdate}
                        />
                    </>
                )
            case 'About us':
                return (
                    <>
                        <MyEditor
                            titleObject={{
                                text: aboutUsEn.title,
                                state: aboutUsTitleEditMode.en,
                                setState: setAboutUsTitleEditMode
                            }}
                            id={staticPagesId['about_us']}
                            editorValue={aboutUsEn.text}
                            language={languages.EN}
                            onChange={handleChange}
                            handleUpdate={handleUpdate}
                        />
                        <MyEditor
                            titleObject={{
                                text: aboutUsAr.title,
                                state: aboutUsTitleEditMode.ar,
                                setState: setAboutUsTitleEditMode
                            }}
                            id={staticPagesId['about_us']}
                            editorValue={aboutUsAr.text}
                            language={languages.AR}
                            onChange={handleChange}
                            handleUpdate={handleUpdate}
                        />
                    </>
                )
            default:
                return null
        }
    }

    return (
        <>
            <PageTitleWrapper>
                <PageHeader
                    pageTitle='Edit static pages'
                    pageSubtitle='You can edit your static pages here'
                    handleTabsChange={handleTabsChange}
                    activeTab={activeTab}
                />
            </PageTitleWrapper>
            <Grid container display='flex' justifyContent='center' p={1}>
                <Grid item display='flex' flexDirection='column' xl={10} lg={10} md={10} sm={12} xs={12}>
                    {renderEditor()}
                </Grid>
            </Grid>
        </>

    )
}

export default EditStatics


const MyEditor = ({
    titleObject,
    editorValue,
    id,
    onChange,
    language,
    handleUpdate,
}) => {
    const { checkPermission } = useCheckPermission()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const onUpdate = async (slug) => {
        if (!checkPermission('UPDATE_STATIC_PAGE')) return

        const data = {}
        let html = convertToRaw(editorValue.getCurrentContent())
        html = draftToHtml(html)

        switch (slug) {
            case 'title':
                setLoading(id + language.slug + 'title')
                if (language.slug === 'EN') data.title = titleObject.text
                else if (language.slug === 'AR') data.titleAr = titleObject.text
                await handleUpdate(id, data)
                if (language.slug === 'EN') titleObject.setState(obj => ({ ...obj, en: false }))
                else if (language.slug === 'AR') titleObject.setState(obj => ({ ...obj, ar: false }))
                setLoading(false)
                break;
            case 'body':
                setLoading(id + language.slug + 'body')
                if (language.slug === 'EN') data.html = html
                else if (language.slug === 'AR') data.htmlAr = html
                await handleUpdate(id, data)
                setLoading(false)
                break;
            default:
                break;
        }
    }

    const handleChangeEditMode = (boolean) => {
        if (language.slug === 'EN') titleObject.setState(obj => ({ ...obj, en: boolean }))
        else if (language.slug === 'AR') titleObject.setState(obj => ({ ...obj, ar: boolean }))
    }

    const renderIcon = () => {
        if (loading === id + language.slug + 'title') {
            return <CircularProgress style={{ color: '#fff' }} size={'20px'} />
        } else {
            if (titleObject.state) {
                return <ArrowUpwardIcon style={{ color: '#fff' }} fontSize='small' />
            } else {
                return <EditIcon style={{ color: '#fff' }} fontSize='small' />
            }
        }
    }

    const renderTitleElement = () => {
        if (titleObject.state) {
            return <TextField
                id="standard-basic" label="Edit title" variant="standard"
                style={{ width: '200px' }}
                value={titleObject.text}
                onChange={e => onChange(e, id, language.slug, 'title')}
            />
        } else {
            return <Typography variant='h4' >{titleObject.text}</Typography>
        }
    }

    return (
        <>
            <Typography variant='button'>Edit {staticPageIdDict[id]} ({language.name})</Typography>
            <StyledEditorCard>
                <Box className='title-wrapper'>
                    <Box>
                        {renderTitleElement()}
                    </Box>
                    <Box className='actions-wrapper'>
                        {titleObject.state &&
                            <Box className='title-edit-button' onClick={() => handleChangeEditMode(false)}>
                                <CloseIcon style={{ color: '#fff' }} fontSize='small' />
                            </Box>
                        }
                        <Box
                            className='title-edit-button'
                            style={{
                                opacity: (!checkPermission('UPDATE_STATIC_PAGE') || loading === id + language.slug + 'title') && '0.5',
                                cursor: (!checkPermission('UPDATE_STATIC_PAGE') || loading === id + language.slug + 'title') && 'inherit'
                            }}
                            onClick={() =>
                                (checkPermission('UPDATE_STATIC_PAGE') || loading !== id + language.slug + 'title') &&  //**** block api call when loading is true
                                    titleObject.state
                                    ? onUpdate('title') //**** save new title
                                    : handleChangeEditMode(true)
                            } //**** turn on edit mode
                        >
                            {renderIcon()}
                        </Box>
                    </Box>
                </Box>
                <Box className='editor-wrapper'>
                    <Editor
                        editorState={editorValue}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={e => onChange(e, id, language.slug, 'body')}
                    />
                </Box>
                <Box className='actions-wrapper'>
                    <Box />
                    <CustomButton
                        text='Visit '
                        onClick={() => navigate(ROUTE_CONSTANTS.PUBLIC_ROUTES.STATIC_PAGES.GET_BY_DATA(staticPageIdDict[id], language.slug).ABSOLUTE)}
                        style={{ marginRight: '10px' }}
                    />
                    <CustomButton
                        text='Save'
                        loading={loading === id + language.slug + 'body'}
                        disabled={!checkPermission('UPDATE_STATIC_PAGE') || loading === id + language.slug + 'body'}
                        onClick={() => onUpdate('body')}
                    />
                </Box>
            </StyledEditorCard>
        </>
    )
}

const StyledEditorCard = styled(Card)(
    () => `
        width: 100%;
        margin-bottom: 3rem;
        margin-top: 1rem;
        padding: 10px;
        .actions-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
        .editor-wrapper{
            margin-top: 30px;
            min-height: 300px;
        }
        .title-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            .actions-wrapper{
                .title-edit-button{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #f33;
                    margin-left: 8px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        
    `
);



