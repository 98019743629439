import { AxiosInstance } from '../index';

export const adminService = {
	//**** users and admins *****
	getAdminList: (data) =>
		AxiosInstance.post('admin/auth/admins/list', fixPageNumber(data)),
	getUserDetail: (id) => AxiosInstance.get(`admin/auth/userdetail/${id}`), //***** get user details should be get admin details and get customer details
	adminInvitation: (body) => AxiosInstance.post('admin/auth/invitation', body),
	getCustomersList: (data) =>
		AxiosInstance.post('admin/auth/customers/list', fixPageNumber(data)),
	updateAdminDetails: (id, data) =>
		AxiosInstance.put(`admin/auth/update-admin/${id}`, data),
	updateProfileDetails: (data) =>
		AxiosInstance.put(`admin/auth/update-profile/`, data),
	updateAdminPassword: (id, data) =>
		AxiosInstance.put(`admin/auth/set-admin-password/${id}`, data),
	updateMyPassword: (data) =>
		AxiosInstance.post(`admin/auth/set-password`, data),
	toggleUsers: (id, data) => AxiosInstance.put(`admin/auth/toggle/${id}`, data),

	//**** vehicles *****
	createVehicle: (data) => AxiosInstance.post('admin/vehicle/', data),
	updateVehicle: (id, data) => AxiosInstance.put(`admin/vehicle/${id}`, data),
	getVehicleClass: () => AxiosInstance.get('classes'),
	getVehicleList: (data) =>
		AxiosInstance.post('admin/vehicle/list', fixPageNumber(data)),
	getVehicleDetails: (id) => AxiosInstance.get(`admin/vehicle/${id}`),
	toggleVehicleStatus: (id, data) =>
		AxiosInstance.put(`admin/vehicle/toggle/${id}`, data),
	getBrandsList: (data) =>
		AxiosInstance.post('admin/brands/list', fixPageNumber(data)),
	brandToggle: (id, data) =>
		AxiosInstance.put(`admin/brands/toggle/${id}`, data),
	createBrand: (data) => AxiosInstance.post(`admin/brands/`, data),
	updateBrand: (id, data) => AxiosInstance.put(`admin/brands/${id}`, data),
	getClassesList: (data) =>
		AxiosInstance.post('admin/classes/list', fixPageNumber(data)),
	classToggle: (id, data) =>
		AxiosInstance.put(`admin/classes/toggle/${id}`, data),
	createClass: (data) => AxiosInstance.post(`admin/classes`, data),
	updateClass: (id, data) => AxiosInstance.put(`admin/classes/${id}`, data),
	getAttributes: (vehicleId) =>
		AxiosInstance.get(`admin/vehicle/${vehicleId}/attribute/list`),
	updateAttribute: (vehicleId, attributeId, data) =>
		AxiosInstance.put(
			`admin/vehicle/${vehicleId}/attribute/${attributeId}`,
			data
		),
	createAttribute: (vehicleId, data) =>
		AxiosInstance.post(`admin/vehicle/${vehicleId}/attribute/`, data),

	//**** vendors *****
	getVendorsList: (data) =>
		AxiosInstance.post('admin/vendors/list', fixPageNumber(data)),
	createVendor: (data) => AxiosInstance.post('admin/vendors', data),
	updateVendor: (id, data) => AxiosInstance.put(`admin/vendors/${id}`, data),
	getVendorDetails: (id) => AxiosInstance.get(`admin/vendors/${id}`),
	getMyVendorDetails: () => AxiosInstance.get(`admin/vendors/my-vendor`),
	toggleVendorStatus: (id, data) =>
		AxiosInstance.put(`admin/vendors/toggle/${id}`, data),
	vendorWeekends: (id, data) =>
		AxiosInstance.put(`admin/vendors/weekends/${id}`, data),
	vendorHolidays: (id, data) =>
		AxiosInstance.put(`admin/vendors/holidays/${id}`, data),
	addBinding: (id, data) =>
		AxiosInstance.put(`admin/vendors/bindings/${id}`, data),
	getVendorTerms: (vendorId) =>
		AxiosInstance.get(`admin/vendors/${vendorId}/vendor-terms/list`),
	createTerm: (vendorId, data) =>
		AxiosInstance.post(`admin/vendors/${vendorId}/vendor-terms`, data),
	updateTerm: (vendorId, termId, data) =>
		AxiosInstance.put(`admin/vendors/${vendorId}/vendor-terms/${termId}`, data),
	updateWorkingHours: (vendorId, dayId, data) =>
		AxiosInstance.put(`admin/vendors/${vendorId}/work-hours/${dayId}`, data),

	//**** inventory *****
	createInventory: (data) =>
		AxiosInstance.post('admin/vehicle/inventory', data),
	updateInventory: (id, data) =>
		AxiosInstance.put(`admin/vehicle/inventory/${id}`, data),
	getInventoryList: (data) =>
		AxiosInstance.post('admin/vehicle/inventory/list', fixPageNumber(data)),
	getInventoryDetails: (id) =>
		AxiosInstance.get(`admin/vehicle/inventory/${id}`),
	toggleInventoryStatus: (id, data) =>
		AxiosInstance.put(`admin/vehicle/inventory/toggle/${id}`, data),
	blockInventory: (id, data) =>
		AxiosInstance.post(`admin/vendors/inventory-block/${id}`, data),
	removeBlockedInventory: (id, data) =>
		AxiosInstance.delete(`admin/vendors/inventory-block/${id}`, data),

	//**** countries *****
	getCountriesList: (data) =>
		AxiosInstance.post('admin/country/list', fixPageNumber(data)),
	createCountry: (data) => AxiosInstance.post('admin/country', data),
	updateCountry: (id, data) => AxiosInstance.put(`admin/country/${id}`, data),
	countryToggle: (id, data) =>
		AxiosInstance.put(`admin/country/toggle/${id}`, data),

	//**** colors *****
	getColorsList: (data) =>
		AxiosInstance.post('admin/colors/list', fixPageNumber(data)),
	createColor: (data) => AxiosInstance.post('admin/colors', data),
	updateColor: (id, data) => AxiosInstance.put(`admin/colors/${id}`, data),
	colorToggle: (id, data) =>
		AxiosInstance.put(`admin/colors/toggle/${id}`, data),

	//**** airports *****
	getAirportsList: (data) =>
		AxiosInstance.post('admin/country-air/list', fixPageNumber(data)),
	createAirport: (data) => AxiosInstance.post('admin/country-air', data),
	updateAirport: (id, data) =>
		AxiosInstance.put(`admin/country-air/${id}`, data),
	airportToggle: (id, data) =>
		AxiosInstance.put(`admin/country-air/toggle/${id}`, data),

	//**** orders *****
	getOrdersList: (vendorId, data) =>
		AxiosInstance.post(`admin/orders/list/${vendorId}`, fixPageNumber(data)),
	getOrderDetails: (id) => AxiosInstance.get(`admin/orders/${id}`),
	orderUpdateStatus: (id, data) =>
		AxiosInstance.put(`admin/orders/status/${id}`, data),
	refundOrder: (id, data) =>
		AxiosInstance.post(`admin/orders/refund/${id}`, data),

	//**** offline order *****
	offlineOrderGetCountryCodes: (lang) =>
		AxiosInstance.get('misc/country-codes', { headers: { lang } }),
	offlineOrderCheckUser: (data) =>
		AxiosInstance.post('admin/offline-orders/check-user', data),
	offlineOrderCheckEmail: (data) =>
		AxiosInstance.post('admin/offline-orders/exist/email', data),
	offlineOrderFilterInformation: (data, currency) =>
		AxiosInstance.post(
			`admin/offline-orders/inventory-list/filter-information`,
			data,
			{ headers: { currency } }
		),
	offlineOrderInventoryList: (data, currency) =>
		AxiosInstance.post(`admin/offline-orders/inventory-list`, data, {
			headers: { currency },
		}),
	offlineOrderInventoryDetail: (inventory_id, currency) =>
		AxiosInstance.get(`vendors/${inventory_id}/information`, {
			headers: { currency },
		}),
	offlineOrderUploadMedia: (file, config) =>
		AxiosInstance.post(`admin/media`, file, config),
	offlineOrderCreateOrder: (data) =>
		AxiosInstance.post(`admin/offline-orders/create`, data),

	offlineOrderPaymentDetail: (id, currency) =>
		AxiosInstance.get(`admin/offline-orders/${id}/payment-detail`, {
			headers: { currency },
		}),
	offlineOrderGeneratePayment: (id, body, currency) =>
		AxiosInstance.post(`admin/offline-orders/${id}/payment-generate`, body, {
			headers: { currency, system: 'BROWSER', device: 'WEB' },
		}),
	offlineOrderSmsPaymentLink: (id) =>
		AxiosInstance.post(`/admin/offline-orders/${id}/sms-payment-url`, {}),
	offlineOrderEmailPaymentLink: (id) =>
		AxiosInstance.post(`/admin/offline-orders/${id}/email-payment-url`, {}),

	//**** Static pages *****
	editStaticPage: (id, data) =>
		AxiosInstance.put(`admin/misc/static/${id}`, data),
	getStaticPage: (type, language) =>
		AxiosInstance.get(`misc/static/${type}/${language}`),
	getStaticPages: () => AxiosInstance.get(`admin/misc/static`),

	//**** Notification *****
	deleteOrderNotification: (id) =>
		AxiosInstance.delete(`admin/misc/notification/${id}`),
	getMyNotifications: () => AxiosInstance.get('admin/misc/my-notifications'),
	readNotification: (id) =>
		AxiosInstance.put(`admin/misc/notification/read/${id}`),

	//**** general *****
	getBrands: (data) => AxiosInstance.post('admin/brands/list', data),
	getPermission: () => AxiosInstance.get('admin/misc/role-perms'),
	togglePermission: (id, data) =>
		AxiosInstance.put(`admin/misc/toggle-perm/${id}`, data),
	getCurrencyExchange: () => AxiosInstance.get('admin/misc/currency-exchange'),
	getReports: () => AxiosInstance.get('admin/misc/my-reports'),

	//**** country air *****
	getAirportTerminalList: (id) =>
		AxiosInstance.get(`admin/country-air/${id}/list/terminals`),

	//**** vendor airports *****
	getVendorAirportList: (id, data) =>
		AxiosInstance.post(`admin/vendors/${id}/list-airport`, data),
	getVendorAvailableAirportList: (id) =>
		AxiosInstance.post(`admin/vendors/${id}/list-available-airport`),
	addVendorAirport: (id, data) =>
		AxiosInstance.post(`admin/vendors/${id}/add-airport`, data),
	deleteVendorAirport: (id, data) =>
		AxiosInstance.delete(`admin/vendors/${id}/delete-airport`, data),
	updateVendorAirportTerminal: (id, data) =>
		AxiosInstance.put(`admin/vendors/${id}/update-airport-terminal`, data),

	//**** currencies *****
	getCurrenciesList: () => AxiosInstance.get('payment/currencies'),
	getCurrenciesListFullDetail: () =>
		AxiosInstance.get('admin/currency/currencies/list'),
	updateCurrency: (data) =>
		AxiosInstance.put('admin/currency/currencies/decimal-digit', data),
};

const fixPageNumber = (data) => {
	//***** Pagination component in material UI start from page 0, our backend start from 1,
	// For dealing with API call and handle pagination functions I did this manipulation *****
	let manipulatedPage = data.page + 1;
	data['page'] = manipulatedPage;

	return data;
};
