export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const phoneRegExpStartWithPlus = /^\+([1-9]{1,4}[ \\-]*){0,1}([0-9]{2,4}[ \\-]*){0,1}[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const validGearbox = ['AUTOMATIC', 'MANUAL']
export const adminRolesOptions = [
    { label: 'Admin', value: 'ADMIN' },
    { label: 'Vendor Admin', value: 'VENDORADMIN' }
]
export const adminRoles = {
    ADMIN: 'ADMIN',
    CUSTOMER: 'CUSTOMER',
    SUPER_ADMIN: 'SUPERADMIN',
    VENDOR_ADMIN: 'VENDORADMIN',
    NIZEK_ADMIN: 'NIZEKADMIN'
}