import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  useTheme,
  Tooltip,
  Typography,
  styled,
  CircularProgress
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { Link as RouterLink } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { useUser } from '../../../../../contexts/GlobalContext';
import { adminService } from '../../../../../api/services/admin';
import { useNotificationSocket } from '../../../../../hooks/useNotificationSocket';
import { ROUTE_CONSTANTS } from '../../../../../constants/AppRoutes';
import CloseIcon from '@mui/icons-material/Close';

const HeaderNotifications = () => {
  const ref = useRef(null);
  const newNotification = useNotificationSocket()
  const [notifications, setNotification] = useState([])
  const [notificationCount, setNotificationCount] = useState(0)
  const [isOpen, setOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const theme = useTheme();
  const user = useUser()
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleDeleteNotification = async (e, item) => {
    e.stopPropagation()
    let tempNotifications = notifications.map(obj => ({ ...obj }))
    
    try{
      setDeleteLoading(item.id)
      await adminService.deleteOrderNotification(item.id) 
      tempNotifications = tempNotifications.filter(eachNotification => eachNotification.id !== item.id)
      setNotification(tempNotifications)
      setDeleteLoading(false)
    }catch(error){
      setDeleteLoading(false)
      console.log(error);
    }
  }

  const handleReadNotification = async item => {
    if(item.readed) return 
    let tempNotifications = notifications.map(obj => ({ ...obj }))
  
    try{
      await adminService.readNotification(item.id)
      tempNotifications.forEach(eachNotification => {
        let tempEachNotification = eachNotification
        if(eachNotification.id === item.id) tempEachNotification.readed = true
        return tempEachNotification
      })
      setNotification(tempNotifications)
      if( notifications.filter(eachNotification => !eachNotification.readed).length > 0 ) {
        setNotificationCount(notifications.filter(eachNotification => !eachNotification.readed).length - 1)
      }
    }catch(error) {
      console.log(error);
    }
  }

  const getMyNotification = async () => {
    try {
      let notifyResponse = await adminService.getMyNotifications()

      setNotification(notifyResponse.data.data.items)
      setNotificationCount(notifyResponse.data.data.items.filter(eachNotification => !eachNotification.readed).length)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSetNewNotificationFromSocket = () => {
    let tempNotifications = notifications.map(obj => ({ ...obj }))
    tempNotifications = [newNotification, ...tempNotifications]
    setNotification(tempNotifications)
    setNotificationCount(numbers => numbers + 1)
  }

  useEffect(() => {
    getMyNotification()
  }, [])

  useEffect(() => {
    if(notifications.length === 0) handleClose()
  }, [notifications])

  useEffect(() => {
    if (Object.keys(newNotification).length !== 0)
      handleSetNewNotificationFromSocket()
  }, [newNotification])

  return (
    <>
      <Tooltip arrow title={'Notifications'}>
        <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={notificationCount}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon color='secondary' />
          </NotificationsBadge>
        </IconButtonPrimary>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{
            p: 2
          }}
          display="flex"
          justifyContent="space-between"
          style={{minWidth: '300px'}}
        >
          <Typography variant="h5">{'Notifications'}</Typography>
          {/* <Typography
            href="#"
            variant="caption"
            sx={{
              textTransform: 'none'
            }}
          >
            {'Mark all as read'}
          </Typography> */}
        </Box>
        <Divider />
        <List
          sx={{
            p: 2
          }}
        >
          {
            notifications.map(item => {
              return <Box key={item.id}>
                <ListItem
                  sx={{
                    display: { xs: 'block', sm: 'flex' }
                  }}
                  style={{paddingTop: '20px', cursor: 'inherit'}}
                  button
                  selected={!item.readed}
                  onMouseEnter={() => handleReadNotification(item)}
                >
                  <Box flex={1}>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      justifyContent="space-between"
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold'
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          textTransform: 'none'
                        }}
                      >
                        {formatDistance(new Date(item?.createdAt), new Date(), {
                          addSuffix: true
                        })}
                      </Typography>
                    </Box>
                    <Typography
                      component={RouterLink}
                      variant="body2"
                      onClick={handleClose}
                      to={ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.GET_BY_DATA(user.vendorId).GET_ORDER_BY_DATA(item?.targetId).ABSOLUTE}
                      sx={{
                        color: theme.colors.error.main
                      }}
                    >
                      {`ORDER-${item?.targetId}`}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      — {item?.description}
                    </Typography>
                  </Box>
                  <Box 
                    onClick={e => handleDeleteNotification(e, item)} 
                    style={{
                      position: 'absolute', 
                      padding: '5px', 
                      top: '0px', 
                      right: '0px', 
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    {deleteLoading === item.id ? <CircularProgress  size="15px"/> : <CloseIcon style={{ color: '#000' }} fontSize='small' />}
                  </Box>
                </ListItem>
                <Divider
                  sx={{
                    my: 1
                  }}
                  component="li"
                />
              </Box>
            })
          }
        </List>
        <Divider />
        {/* <Box m={1}>
          <Button color="secondary" fullWidth>
            {'View all notifications'}
          </Button>
        </Box> */}
      </Popover>
    </>
  );
}

export default HeaderNotifications;

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${theme.palette.error.main};
        color: ${theme.palette.error.contrastText};
        min-width: 18px; 
        height: 18px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    margin-left: ${theme.spacing(1)};
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    transition: ${theme.transitions.create(['background', 'color'])};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);
