import { AxiosInstance, RawAxiosInstance } from "../index";

export const authService = {
    login(email, password) {
        return AxiosInstance.post("admin/auth/login", {
            email,
            password
        })
    },
    logout() {
        return AxiosInstance.get("admin/auth/logout")
    },
    forgotPassword(body) {
        return AxiosInstance.post("auth/local/forgot", body);
    },
    resetPassword(body, token) {
        return RawAxiosInstance.post("auth/reset-pass", body, {headers: {Authorization: `Bearer ${token}`}});
    },
    getProfile: () =>  AxiosInstance.get("admin/auth/me")
};