import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { authService } from '../../api/services/auth'
import { ROUTE_CONSTANTS } from '../../constants/AppRoutes'
import useToast from '../../hooks/useToast' 
import { errorMessage } from '../../utils/errorTypeDetector'
import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
} from '@mui/material';
import Logo from './components/Logo';
import SuccessCard from './components/SuccessCard'

const ResetPassword = () => {
    const { search } = useLocation()
    const { toast } = useToast()
    const navigate = useNavigate();
    const secRef = useRef(null)
    const deepLinkRef = useRef({})
    const [passwordUpdated, setPasswordUpdated] = useState(false)
   
    const getParams = () => {
        const queryParams = new URLSearchParams(search)
        const secret = queryParams.get("sec")
        const deepLinkBoolean = queryParams.get("deeplink")

        secRef.current = secret
        deepLinkRef.current = {hasDeepLink: !!deepLinkBoolean} 
    }

    const handleRedirectToApp = () => {
        window.location.replace('gearapp://open-login')
    }

    const handleResetPassword = async (values, setErrors, setStatus, setSubmitting) => {
        const bearerToken = secRef.current
        if (bearerToken === null) return 

        const { password } = values
        const data = { password }
        try{
            await authService.resetPassword(data, bearerToken)
            setStatus({ success: true })
            setSubmitting(false)
            toast('Password successfully changed', 'success')
            if(deepLinkRef.current.hasDeepLink) {
                setPasswordUpdated(true)
            }else {
                navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE, { replace: true }) // **** if user came from APP we will not redirect him/her 
            }
        }catch(error) {
            setStatus({ success: false });
            setSubmitting(false);
            toast(errorMessage(error), 'error')
        }
    }

    useEffect(() => {
        getParams()
    }, [])
    
    if(passwordUpdated) return (
        <SuccessCard 
            message='Password successfully updated!' 
            onClickButton={handleRedirectToApp}
        />
    )

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Box
                style={{
                    maxWidth: '100%',
                    width: '400px',
                }}>
                <Logo />
                <Typography variant="h4" style={{marginBottom: '20px', textAlign: 'center'}}>
                    Reset your password
                </Typography>
                <Formik
                    initialValues={{
                        password: '',
                        confirmPassword: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        password: Yup.string()
                            .min(8)
                            .max(255)
                            .required('The password field is required'),
                        confirmPassword: Yup.string()
                            .required('The confirm password field is required')
                            .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    })}
                    onSubmit={(values, { setErrors, setStatus, setSubmitting }) => handleResetPassword(values, setErrors, setStatus, setSubmitting)}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <TextField
                                error={Boolean(touched.password && errors.password)}
                                fullWidth
                                margin="normal"
                                helperText={touched.password && errors.password}
                                label={'Password*'}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="password"
                                value={values.password}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                fullWidth
                                margin="normal"
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                label={'Confirm Password*'}
                                name="confirmPassword"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="password"
                                value={values.confirmPassword}
                                variant="outlined"
                            />
                            <Button
                                sx={{
                                    mt: 3
                                }}
                                color="primary"
                                startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                                disabled={isSubmitting}
                                type="submit"
                                fullWidth
                                size="large"
                                variant="contained"
                            >
                                {'Submit'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    )
}
export default ResetPassword

